<template>
  <div class="inline-block">
    <ColorTextBtn class="line" type="danger" @click="visible = true">删除账号 </ColorTextBtn>
    <BaseDialog :append-to-body="true" :dialogVisible.sync="visible" title="确认" width="40%">
      <div class="head">
        <span style="font-size: 12px">确认删除该账号</span>
        <span style="display: block; padding-top: 6px; font-size: 12px"
          >后台将删除此账号的所有账号信息</span
        >
      </div>
      <template #footer>
        <el-button size="small" @click="visible = false">取消</el-button>
        <el-popover placement="top" v-model="requireBtnVisible">
          <p>确认删除?</p>
          <div>
            <el-button size="small" @click="requireBtnVisible = false">取消</el-button>
            <el-button type="danger" @click="required" size="small"  :loading="loading">确定</el-button>
          </div>
          <el-button slot="reference" size="small" type="danger">确定</el-button>
        </el-popover>
      </template>
    </BaseDialog>
  </div>
</template>

<script>
import { deleteAccount } from '@/api/user'
export default {
  components: {},
  props: {
    row:{
      type:Object
    },
    init:{
      type:Function
    }
  },
  data() {
    return {
      visible: false,
      requireBtnVisible: false,
      loading:false
    }
  },
  computed: {},
  watch: {},
  methods: {
    async required() {
      try {
        this.loading = true
        const {code} = await deleteAccount({id:this.row.id})
        if($SUC({code})){
          this.$message.success('删除账号成功')
          this.visible = false
          this.init()
        }else{
          this.$message.error('删除账号失败')
        }
      } catch (error) {
        console.log(error);
      }finally{
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.dialog-footer .el-popover__reference-wrapper {
  .el-button {
    margin-top: 5px;
  }
}
</style>
